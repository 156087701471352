import {Grid, Box} from '@mui/material';
import useWindowDimensions from '../../components/windowDimensions';
import CustomBar from '../../components/CustomBar';
import * as React from 'react';
import logo from '../../resources/images/metal_white.png'
import fearsomeEngineCover from "../../resources/images/fearsomeEngine/FearsomeEngineFrontCoverSmall.png"
import fearsomeEngineBackground from "../../resources/images/fearsomeEngine/fearsomeEngineLandscape.jpg"
import { fearsomeAlbumAltText } from "../releases/Releases";

export const bandcampPath = "https://ripship.bandcamp.com";
export const spotifyPath = "https://open.spotify.com/artist/1yx7HUETsdOE9oP6ISSVSX?si=NS-IbuAwSHaOMiOR70V1TA";
export const instagramPath = "https://www.instagram.com/ripship_/";
export const facebookPath = "https://www.facebook.com/ripshipband";
export const onlyfansPath = "https://www.youtube.com/watch?v=dQw4w9WgXcQ";

const socialButtons = 
[
    {path: bandcampPath, primary: "red", secondary: "lightBlue", label: "bandcamp"},
    {path: spotifyPath, primary: "yellow", secondary: "blue", label: "spotify"},
    {path: instagramPath, primary: "lightGreen", secondary: "blue", label: "instagram"},
    {path: facebookPath, primary: "blue", secondary: "red", label: "facebook"},
    {path: onlyfansPath, primary: "orange", secondary: "purple", label: "onlyfans"}
]

const releaseButtons = 
[
    {label: "spotify", path: "https://open.spotify.com/album/0hHGox1BLcflQuy51xUxuA?si=LohPvfYGRluAp5Ty8G5IVQ&nd=1"},
    {label: "bandcamp", path: "https://ripship.bandcamp.com/album/fearsome-engine"},
    {label: "youtube", path: "https://www.youtube.com/playlist?list=OLAK5uy_nxDxHoHnSJbU99XvfMJE0Ku0xDDsS12wg"},
    {label: "apple", path: "https://music.apple.com/nz/album/fearsome-engine/1655598124"}
]

//props = primary, secondary, bgColor, textColor, link, label    
export function LinkButton(props) {
    return (
        <a href={props.path} target="_blank" className='link-button'> 
            <Box sx={{border:1, bgcolor: props.bgColor, color: props.textColor,
                ':hover': {
                bgcolor: props.primary, 
                color: props.secondary},
                p: 2
            }
            }>
            {props.label}</Box>
        </a>
    );
}

function Home() {

    const { height, width } = useWindowDimensions();
    const buttonColour = 'rgba(165, 0, 255, 0.33)';
    const buttonText = 'white';

    return(
        <div style = {{backgroundAttachment: 'fixed', backgroundImage: `url(${fearsomeEngineBackground})`, backgroundSize: 'cover', minHeight: height, minWidth: width, backgroundPosition: "center center"}}>
        <CustomBar isHome></CustomBar>
        <div className={'layout'}>
            { width < 600 &&
                <img
                src={logo} loading="eager"
                style = {{maxHeight: (width*0.75*0.9), maxWidth: (width*0.9)}}
                />
            }
            { width >= 600 &&
                <img
                src={logo} loading="eager"
                style = {{maxHeight: (width*0.75*0.65), maxWidth: (width*0.65), marginTop: '-4%', marginBottom: '-13%', zIndex: -100}}
                />
            }
            <Grid container spacing={2}>
                {socialButtons.map((item, index) => (
                    <Grid item md={2.4} xs={index > 2? 6 : 4}>
                        <LinkButton path = {item.path} primary = {item.primary} secondary = {item.secondary} label = {item.label} bgColor={buttonColour} textColor = {buttonText}/>
                    </Grid>
                ))}
            <Grid item md={12} xs={12}>
                <Release src = {fearsomeEngineCover}></Release>
            </Grid>
            </Grid>
        </div>
        </div>

    );
}

function Release(props) {

    const lyrics = 
    "Ripship's first ever LP, Fearsome Engine, is available now digitally or in a beautiful baby pink vinyl gatefold package via 1:12 records. Check out our bandcamp or the Flying Out store to get a hold of it.\n\n" + 
    "The nine-track album is an evolution of our genre-bending 2020 EP Greebles, expanding on themes of space exploration, technological anxiety and exponential growth. With the help of producer Peter Ruddell, Fearsome Engine makes no compromises. Lush synthetic soundscapes ebb and flow over polyrhythmic drum grooves and crushing guitars, and vocoded vocals provide robotic low-end. We hope you enjoy an uneasy sense of existential dread just beneath the goof. The opening track, The Great Filter, begins with the division of cells in a systematically noisy march. The closer, B13, guides the listener through an eldritch nightmare. From computer supremacy to climate change, Fearsome Engine is an album about everything that ever was, and everything that will ever be.\n\n"+
    "Guitars were recorded by Ryan Brand at Cordial Studios, the rest was recorded and produced by Peter Ruddell (Sulfate, Jazmine Mary). The album was mastered by James Goldsmith."

    return (
        <>
        <header className={'divBorder release'} style={{borderBottom: '0'}}>
            <h1 className={'pink'}>FEARSOME ENGINE</h1>
            <div className={'releaseSubtext'}>!!! ALBUM OUT NOW !!!</div>
        </header>
        <div className={'divBorder release'} style={{borderBottom: '0'}}>
            <Grid container spacing={2}>
                {releaseButtons.map(item => (
                <Grid item md={3} xs={12}>
                    <LinkButton path = {item.path} primary = "yellow" secondary = "red" label = {item.label} bgColor='black' textColor = {'hotPink'}/>
                </Grid>
                ))}
            </Grid>
        </div>
        <div className={'divBorder release'}>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <img style = {{ maxHeight: '100%', maxWidth: '100%'}} src={props.src} alt={fearsomeAlbumAltText}/>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div style={{display: 'table', height: '100%'}}>
                    <div style={{display: 'table-cell', verticalAlign: 'middle', whiteSpace: 'pre-line', color: "white", textAlign: 'left', padding: '10px'}}>
                        <div className={"lyrics"}>{lyrics}</div>
                    </div>
                    </div>
                </Grid>
            </Grid>
        </div>
        <div className={'divBorder release'} style={{borderTop: '0'}}>
            <Grid container spacing={2}>
                <Grid item md = {12} xs = {12}>
                    <Box onClick = {()=> {window.open("https://youtu.be/E9KaY3Nwx2o", '_blank')}}>
                        <Box style={{padding: '4px'}}>
                            <iframe align= "center" style={{flexGrow: 1, height: '45vw', width: '100%'}} src="https://www.youtube.com/embed/E9KaY3Nwx2o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </div>
        </>
    );
}

export default Home;